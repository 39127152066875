import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { getLastVersion } from "../utils/versions";
import { capitalCase } from "change-case";

function SubResource({ resource, subresource }) {
  const lastVersion = getLastVersion(subresource.versions);

  return (
    <Link
      className="doc-portal-subresource"
      to={`/resources/${resource.name}/${subresource.name}/${lastVersion}`}
      id={`resources_${resource.name}_${subresource.name}`}
    >
      <div className="doc-portal-subresource-name">
        {capitalCase(subresource.name)}
      </div>

      <p className="doc-portal-subresource-description">
        {subresource.versions[lastVersion].spec.info.description.split(/\.|\n\n/)[0]}
      </p>
      <FaArrowRight
        className="doc-portal-subresource-arrow"
        style={{ height: "20px", width: "20px" }}
      />
    </Link>
  );
}

function Resource({ resource }) {
  return (
    <div className="doc-portal-resource">
      <h3 id={`resources_${resource.name}`}>{capitalCase(resource.name)}</h3>
      <div className="doc-portal-resource-subresources">
        {resource.subresources.map(subresource => <SubResource resource={resource} subresource={subresource} key={subresource.name} />)}
      </div>
    </div>
  )
}

function ResourcesSummary({ resources }) {
  return (
    <div className="doc-portal-content">
      <h2 id="resources">Resources</h2>
      <div className="doc-portal-summary">
        {resources.map(resource => <Resource resource={resource} key={resource.name} />)}
      </div>
    </div>
  );
}

ResourcesSummary.propTypes = {
  resources: PropTypes.array
};

export default ResourcesSummary;
