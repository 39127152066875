import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import LinkMethod from "./LinkMethod";

// Custom scroll function to add offset
const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth"
  });
};

const LinkOrDiv = ({ link: { to, hash }, children, offset, ...props }) => {
  if (to != null) {
    return <Link to={to} {...props}>{children}</Link>
  } else if (hash != null) {
    return <HashLink to={`#${hash}`} scroll={el => scrollWithOffset(el, offset)} {...props}>{children}</HashLink>
  } else {
    return <div {...props}>{children}</div>
  }
}

export default function SidebarLink({ link, level = 0, offset = 0 }) {
  const { name, isOpen = false, children = [], method } = link;
  const [toggled, setToggle] = useState(level === 0 || isOpen);
  const hasChildren = children.length > 0;
  const isChild = level > 0;
  const linkClass = `doc-portal-link ${
    hasChildren ? "doc-portal-link-parent" : ""
  }  ${isChild ? "doc-portal-link-child" : ""}`;
  const ToggleArrow = toggled ? FaAngleRight : FaAngleDown;
  const toggle = () => {
    if (hasChildren) {
      setToggle(!toggled);
    }
  };
  return (
    <>
      <div className={linkClass}>
        <LinkOrDiv link={link} offset={offset} className="doc-portal-link-header" onClick={toggle}>
          {hasChildren && <ToggleArrow className="doc-portal-link-toggle" />}
          {method && <LinkMethod method={method} />}
          {name}
        </LinkOrDiv>
        <div className="doc-portal-link-content">
          {toggled && children.map(child => (
            <SidebarLink
              key={`${child.name} ${child.method ? `_${child.method}` : ""}`}
              link={child}
              level={level + 1}
              offset={offset}
            />
          ))}
        </div>
      </div>
    </>
  );
}
