import { useContext } from "react";
import { useMatch } from "react-router-dom";
import { capitalCase } from "change-case";
import { getLastVersion } from "./versions";

import ConfigContext from "../context/config";

export const useResourceLinks = () => {
  const { documentation: { resources } } = useContext(ConfigContext)
  const match = useMatch("/resources/:resourceName/:subresourceName")

  return {
    name: "Resources",
    children: resources.map(resource => ({
      name: capitalCase(resource.name),
      isOpen: match != null && match.params.resourceName === resource.name,
      children: resource.subresources.map(subresource => ({
        name: capitalCase(subresource.name),
        to: `/resources/${resource.name}/${subresource.name}/${getLastVersion(subresource.versions)}`,
        isOpen: match != null && match.params.resourceName === resource.name && match.params.subresourceName === subresource.name,
      }))
    }))
  }
}
