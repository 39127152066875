import { partition, uniq, chain } from 'ramda'

const getOperations = (spec) => chain(
  ([path, pathObject]) => ['get','put','post','delete','options','head','patch','trace']
    .filter(method => pathObject[method] != null)
    .map(method => ({ path, method, operation: pathObject[method] })),
  Object.entries(spec.paths),
)

const getOperationLink = ({ path, method, operation }) => ({
  name: operation.summary || operation.operationId || path,
  method,
})

export const getOperationLinks = (spec) => {
  const [operationsWithTags, operationsWithoutTags] = partition(({ operation }) => operation.tags != null && operation.tags.length !== 0, getOperations(spec));

  const declaredTags = (spec.tags || []).map(t => t.name);
  const referencedTags = chain(({ operation }) => operation.tags, operationsWithTags);
  const tags = uniq([...declaredTags, ...referencedTags]);

  return {
    name: "Operations",
    children: [
      ...chain(tag => {
        const matchingOperations = operationsWithTags.filter(({ operation }) => operation.tags.includes(tag));

        return matchingOperations.length === 0 ? [] : [{
          name: tag,
          isOpen: true,
          children: matchingOperations.map(getOperationLink)
        }]
      }, tags),
      ...operationsWithoutTags.map(getOperationLink),
    ],
  }
}
