import React, { useContext } from "react";

import Markdown from "./Markdown";
import ResourcesSummary from "./Home/ResourcesSummary";
import Sidebar from "./Sidebar";
import ConfigContext from "./context/config";
import { useResourceLinks } from "./utils/resource-links";

function Home() {
  const { documentation } = useContext(ConfigContext);
  const { home, resources } = documentation;

  const links = [useResourceLinks()];

  return (
    <>
      <Sidebar links={links} />
      <div className="doc-portal-main doc-portal-container">
        {home.markdowns.map((md, index) => (
          <Markdown key={index} md={md} />
        ))}
        <ResourcesSummary resources={resources} />
      </div>
    </>
  );
}

export default Home;
