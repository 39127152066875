import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import ConfigContext from "./context/config";
import SidebarLink from "./Sidebar/SidebarLink";
import SearchBar from "./Sidebar/SearchBar";
import FilteredLinks from "./Sidebar/FilteredLinks";

export default function Sidebar({ links, offset = 0 }) {
  const { logo } = useContext(ConfigContext);
  const [search, setSearch] = useState("");

  return (
    <aside className="doc-portal-sidebar">
      <Link to="/">
        <img src={logo} width="225px" className="doc-portal-logo" alt="logo" />
      </Link>
      <SearchBar search={search} onChange={setSearch} />
      <div className="doc-portal-links">
        <FilteredLinks links={links} search={search}>
          {filteredLinks => (
            <>
              {filteredLinks.map(link => (
                <SidebarLink key={link.name} link={link} offset={offset} />
              ))}
            </>
          )}
        </FilteredLinks>
      </div>
    </aside>
  );
}
