import React from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { sortVersions } from "../utils/versions";

export default function VersionSelector({
  resource = "",
  subresource = "",
  current = null,
  versions = [],
}) {
  const navigate = useNavigate();
  const versionsOptions = sortVersions(versions).map(version => ({
    value: version,
    label: version
  }));

  const onVersionChange = version => {
    navigate(`/resources/${resource}/${subresource}/${version.value}`);
  };

  return (
    <>
      <label>Versions :</label>
      <Select
        className="doc-portal-version-selector"
        value={{ value: current, label: current }}
        onChange={onVersionChange}
        options={versionsOptions}
        isDisabled={versionsOptions.length <= 1}
      />
    </>
  );
}
