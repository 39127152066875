import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./DocPortal.scss";
import "swagger-ui-react/swagger-ui.css";

import Home from "./Home";
import Subresource from "./Subresource";
import ScrollToTop from "./ScrollToTop";
import ConfigContext from "./context/config";

function DocPortal({ logo, documentation }) {
  return (
    <div className="doc-portal">
      <ConfigContext.Provider value={{ logo, documentation }}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" exact element={<Home/>} />
              <Route path="/resources/:resourceName/:subresourceName/:version" element={<Subresource/>} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </ConfigContext.Provider>
    </div>
  );
}

DocPortal.propTypes = {
  logo: PropTypes.string.isRequired,
  documentation: PropTypes.shape({
    home: PropTypes.shape({
      markdowns: PropTypes.arrayOf(PropTypes.string)
    }),
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        subresources: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            versions: PropTypes.object,
          })
        ),
      })
    )
  }).isRequired
};

export default DocPortal;
